import React    from "react";
import template from "./testimonials.jsx";

class testimonials extends React.Component {
  render() {
    return template.call(this);
  }
}

export default testimonials;
