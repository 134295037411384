import React    from "react";
import template from "./picture.jsx";

class picture extends React.Component {
  render() {
    return template.call(this);
  }
}

export default picture;
