import './App.scss';
import Navbar from './components/navbar'
import Content from './components/content'
import Footer from './components/footer'
import React from 'react';

function App() {
  return (
    <div className='app'>
      <div className='app-container'>
        <Navbar></Navbar>
        <Content className="content-container"></Content>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
