import "./timeline.css";
import React from "react";
import Title from 'components/common/title'
import TimelinePoint from './timeline-point'

function template() {
  const {title, component, data} = this.props.data
  return (
    <div id={title.toLowerCase().split(' ').join('_') + '_anchor'} className="timeline">
      <Title>{title}</Title>
      { data.reverse().map((item, i) => <TimelinePoint key={i} component={component} data={item}></TimelinePoint>) }
    </div>
  );
};

export default template;
