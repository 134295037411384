import "./vspace.css";
import React from "react";

function template() {
  const multiple = !this.props.multiple? 1 : Math.abs(this.props.multiple)
  return (
    <div className="vspace">
      {Array(multiple).fill(undefined).map((el,i) => <div key={i} className="spacer"></div>)}
    </div>
  );
};

export default template;
