import React    from "react";
import template from "./timeline.jsx";

class timeline extends React.Component {
  render() {
    return template.call(this);
  }
}

export default timeline;
