import React    from "react";
import template from "./aboutmetext.jsx";
import moment   from 'moment';

class aboutmetext extends React.Component {
  counter = 1;
  roles = ['Full Stack Development', 'Art', 'Travel']
  learn = ['React.', 'how to play a Flute/Kalimba.', 'clay modelling.']
  constructor(props) {
    super(props)
    this.state = {
      role: this.roles[0],
      experience: moment([2018, 7, 6]).toNow(true),
      learn: this.learn[0]
    }
  }

  componentDidMount() {
    setInterval(function () {
      this.setState({role: this.roles[this.counter%this.roles.length]})
      this.setState({learn: this.learn[this.counter%this.learn.length]})
      this.counter++;
    }.bind(this), 3000);
  }

  render() {
    return template.call(this);
  }

  handleClick(e) {
    window.open("https://bit.ly/punit_resume", "_blank")
  }

  getFlagEmoji(countryCode) {
    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map(char =>  127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
  }
}

export default aboutmetext;
