import React    from "react";
import template from "./experience-item.jsx";

class experienceItem extends React.Component {
  render() {
    return template.call(this);
  }
}

export default experienceItem;
