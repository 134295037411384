import "./footer.scss";
import React from "react";

function template() {
  return (
    <div className="footer py-3 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-6 col-md-4 d-flex justify-content-center justify-content-md-start">
            <a href="https://www.facebook.com/punitkpal/" rel="noreferrer" target='_blank'>
              <i className="lni lni-facebook-original"></i>
            </a>
            <a href="http://bit.ly/punit_linkedin" rel="noreferrer" target='_blank'>
              <i className="lni lni-linkedin-original"></i>
            </a>
            <a href="http://bit.ly/punit_github" rel="noreferrer" target='_blank'>
              <i className="lni lni-github-original"></i>
            </a>
            <a href="mailto:punitkpal@gmail.com" rel="noreferrer" target='_blank'>
            <i className="lni lni-postcard"></i>
            </a>
          </div>
          <div className="col-6 col-md-4 d-flex justify-content-center">
            <a href="https://www.buymeacoffee.com/punitkpal" target="_blank" rel="noreferrer"><img className="bmac" src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" /></a>
          </div>
          <div className="policy-statments col-12 col-md-4 d-flex flex-row justify-content-around flex-md-column align-items-md-end ">
            <a href="/tnc.html" rel="noreferrer" target='_blank'>Terms of Service</a>
            <a href="/privacy.html" rel="noreferrer" target='_blank'>Privacy Policy</a>
            <a href="/refund.html" rel="noreferrer" target='_blank'>Refund Policy</a>
          </div>
          <div className="credits col-12 d-flex justify-content-center">
            Engineered with &nbsp;<span className="heart">&#10084;</span> &nbsp;&nbsp;&bull;&nbsp; &#169; Copyrights reserved with Author.
          </div>
        </div>
      </div>
    </div>
  );
};

export default template;
