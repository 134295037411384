import "./custom-button.css";
import React from "react";

function template() {
  return (
    <button type="button" className={`cbtn cbtn-${this.props.type}`} onClick={(e) => this.handleOnClick(e)}>{this.props.children}</button>
  );
};

export default template;
