import "./content.scss";
import React from "react";
import AboutMe from './aboutme'
import Skills from './../skills'
import Timeline from './../timeline'
import ContactMe from './../contactme'
// eslint-disable-next-line
import Testimonials from './../testimonials'
import Vspace from './../common/vspace'

function template() {
  return (
    <div className="content">
      <AboutMe></AboutMe>
      <Skills></Skills>
      {/* <Testimonials></Testimonials> */}
      {this.state.sections.map((section, i) => <div key={i}><Timeline  data={section}></Timeline><Vspace multiple={10}></Vspace></div>)}
      <ContactMe></ContactMe>
    </div>
  );
};

export default template;
