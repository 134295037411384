import "./education-item.scss";
import React from "react";

function template() {
  const {data} = this.props
  return (
    <div className="education-item">
      <div>
        <span className="school">{data.school}</span>
        <span className="location">&nbsp;{data.location}</span>
      </div>
      <div className="mt-2">
        <span className="course">{data.course}</span>
        { data.subject ? <span className="branch">&nbsp;{data.subject}</span> : ""}
        { data.marks.type === "CGPA" ? <span className="marks">&nbsp;|&nbsp;CGPA - {data.marks.obtained}/{data.marks.max}</span> : <span className="marks">&nbsp;|&nbsp; {data.marks.obtained}%</span>}
      </div>
      <div className="highlights mt-2">
          <ul>
            {data.highlights.map((el, i) => <li key={i}>{el}</li>)}
          </ul>
      </div> 
    </div>
  );
};

export default template;
