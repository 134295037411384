export const EducationList = [
    {
        "year": {
            "to": "2010",
        },
        "course": "Class 10",
        "school": "Kendriya Vidyalaya EME",
        "board": "CBSE",
        "location": "Vadodara",
        "marks": {
            "max": 10,
            "obtained": 9.8,
            "type": "CGPA"
        },
        "highlights": [
            "Held 2nd position in the school"
        ]
    },
    {
        "year": {
            "to": "2012",
        },
        "course": "Class 12",
        "subject": "(Science)",
        "school": "Kendriya Vidyalaya EME",
        "board": "CBSE",
        "location": "Vadodara",
        "marks": {
            "obtained": 92.8,
            "type": "percentage"
        },
        "highlights": [
            "Secured 1st position in the school",
            "Frequent Quizzer representing the school",
        ]
    },
    {
        "year": {
            "from": "2013",
            "to": "2018",
        },
        "course": "Dual Degree (B.Tech + M.Tech)",
        // "subject": "Ocean Engineering and Naval Architecture",
        "school": "Indian Institute of Technology",
        "location": "Kharagpur",
        "marks": {
            "max": 10,
            "obtained": 7.78,
            "type": "CGPA"
        },
        "highlights": [
            "Secured rank in top 1% of all candidates in JEE (Advanced)",
            "Steering Committee Member of Social-cultural Festival Spring Fest",
            "Helped organize Departmental Tech fest Samudramanthan",
            "Worked for education of under-privileged children as a part of the NGO - Gopali Youth Welfare Society",
            "Secured Pre-placement Offer from Morgan Stanley"
        ]
    }
]