import React    from "react";
import template from "./navbar.jsx";

class navbar extends React.Component {
  render() {
    return template.call(this);
  }

  goto = (elId) => (event) => {
    const el = document.getElementById(`${elId}_anchor`);
    console.log(elId)
    if (el) 
      setTimeout(() => {
        el.scrollIntoView({behavior: 'smooth'})
      }, 0)
    return
  }
}

export default navbar;
