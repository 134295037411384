import "./timeline-point.scss";
import React from "react";

function template() {
  const {component, data} = this.props
  return (
    <div className="timeline-point d-flex flex-nowrap flex-column flex-xl-row">
      <div className="left-column d-inline-flex flex-shrink-0 justify-content-xl-end me-2 ms-1">
        <div className="pointer tp-left"></div>
        <div className="justify-content-center flex-shrink-0  align-items-center mx-2">
          <span className="year">{data.year.from ? <span>{data.year.from}-</span>: ""}{data.year.to}</span>
        </div>
        <div className="pointer tp-right"></div>
      </div>
      <div className="right-column d-inline-flex flex-shrink-1 flex-column ps-3 py-4 pt-2">
        { React.createElement(component, { data })}
      </div>
    </div>
  );
};

export default template;
