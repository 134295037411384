import "./aboutmetext.scss";
import React from "react";
import CustomButton from "./../../../common/custom-button/custom-button";
import TextTransition, { presets } from "react-text-transition";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function template() {
  return (
    <div className="aboutmetext d-flex justify-content-md-center justify-content-start flex-column p-0 ps-md-5 col-sm-12 col-md-7 col-lg-7">
      <div className="aboutmetextblock d-flex flex-column align-items-md-start align-items-center">
        <h4 className="d-flex role"><TextTransition springConfig={presets.wobbly}>{this.state.role}</TextTransition></h4>
        <h1 className="d-flex">Punit Pal</h1>
        <div className="aboutmetext-content">Technocrat. Artist. Dreamer.<br></br>Hi, I am a full-stack development Engineer with more than {this.state.experience} of experience in development of end-to-end application. I am an amateur artist and a travel enthusiast who loves trying new things. Currently, I am trying to learn -
          <br></br>
          <TextTransition springConfig={presets.wobbly}><p className="learning-highlight">{this.state.learn}</p></TextTransition>
        </div>
        <div className="countries-travelled aboutmetext-content">
          Been across - 
          <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom mt-n2`}>India</Tooltip>}><img src="https://flagcdn.com/in.svg" width="25" alt="India"></img></OverlayTrigger>
          <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom mt-n2`}>Maldives</Tooltip>}><img src="https://flagcdn.com/mv.svg" width="25" alt="Maldives"></img></OverlayTrigger>
          <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom mt-n2`}>Bhutan</Tooltip>}><img src="https://flagcdn.com/bt.svg" width="25" alt="Bhutan"></img></OverlayTrigger>
          <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom mt-n2`}>Vietnam</Tooltip>}><img src="https://flagcdn.com/vn.svg" width="25" alt="Vietnam"></img></OverlayTrigger>
        </div>
        <CustomButton className="d-flex" type="primary" handleClick={this.handleClick.bind(this)}>Download CV</CustomButton>
      </div>
    </div>
  );
};

export default template;
