import React   from "react";
import { CAPTCHA_ERR } from "utils/constants.js";
import template from "./contactme.jsx";

class contactme extends React.Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef()
    this.state = {"error_msg": ""}
  }

  
  render() {
    return template.call(this);
  }

  onRecaptchaChange(e, ctx) {
    console.log("change")
    ctx.showError('')
  }

  handleSubmit(e, ctx) {
    e.preventDefault();
    if (ctx.recaptchaRef.current.getValue()) {
      ctx.showError('')
      e.target.submit()
      return;
    } 
    ctx.showError(CAPTCHA_ERR)
  }

  formChange(e, ctx) {
    ctx.showError('')
  }

  showError(msg) {
    this.setState({"error_msg": msg})
  }
}

export default contactme;
