import React    from "react";
import { Skills } from "utils/data/skills.js";
import template from "./skills.jsx";

class skills extends React.Component {
  state = {
    list: Skills
  }
  render() {
    return template.call(this);
  }
}

export default skills;
