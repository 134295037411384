import EducationItem from 'components/timeline/education-item'
import ExperienceItem from "components/timeline/experience-item";
import { EducationList } from "./education";
import { ExperienceList } from "./experience";

const data = [
    {
        "section": "education",
        "title": "Education",
        "component": EducationItem,
        "data": EducationList
    },
    {
        "section": "experience",
        "title": "Work Experience",
        "component": ExperienceItem,
        "data": ExperienceList
    }
]

export default data;