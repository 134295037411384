import "./picture.scss";
import React from "react";

function template() {
  return (
    <div className="picture col-sm-12 col-md-5 col-lg-5">
      <div className="d-flex justify-content-sm-center justify-content-md-end justify-content-lg-end">
        <div className="picture-container">
          <div className="picture-placeholder"></div>
        </div>
      </div>
    </div>
  );
};

export default template;
