import "./aboutme.css";
import React from "react";
import Picture from './picture'
import AboutMeText from './aboutmetext'

function template() {
  return (
    <div id="aboutme_anchor" className="aboutme row flex-row">
      <Picture></Picture>
      <AboutMeText></AboutMeText>
    </div>
  );
};

export default template;
