import React    from "react";
import template from "./testimonial.jsx";

class testimonial extends React.Component {
  render() {
    return template.call(this);
  }
}

export default testimonial;
