import React    from "react";
import template from "./custom-button.jsx";

class CustomButton extends React.Component {
  render() {
    return template.call(this);
  }

  handleOnClick(e) {
    this.props.handleClick(e)
  }
}

export default CustomButton;
