import React    from "react";
import template from "./timeline-point.jsx";

class timelinePoint extends React.Component {
  render() {
    return template.call(this);
  }
}

export default timelinePoint;
