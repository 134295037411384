export const ExperienceList = [
    // {
    //     "year": {
    //         "from": "May, 2015",
    //         "to": "June, 2015"
    //     },
    //     "type": "Internship",
    //     "company": "Eckovation",
    //     "location": "New Delhi, India",
    //     "designation": "Backend Development Intern",
    //     "role": "Backend Development",
    //     "highlights": [
    //         "Designed a NodeJS server for Real-Time Android Chat Application in NodeJS (socket.io) with MVC structure using ExpressJS",
    //         "Implemented a scalable MEAN stack with a robust combination of MongoDB and Redis data caching",
    //         "Deployed scripts to simulate the application with 2Million+ users chatting simultaneously"
    //     ]
    // },
    // {
    //     "year": {
    //         "from": "May, 2016",
    //         "to": "June, 2016"
    //     },
    //     "type": "Internship",
    //     "company": "Semantics3",
    //     "location": "Bengaluru, India",
    //     "designation": "Full Stack Intern",
    //     "role": "Full Developer",
    //     "highlights": [
    //         "Carried out feature addition and bug fixes in Administrative Console written in AngularJS",
    //         "Developed cross-platform automation tools to improve productivity of the team, using Slack, Hubot, Github and Javscript",
    //         "Reduced manual operation on 750+ machines to single line commands to increase team productivity"
    //     ]
    // },
    // {
    //     "year": {
    //         "from": "May, 2017",
    //         "to": "June, 2017"
    //     },
    //     "type": "Internship",
    //     "company": "Morgan Stanley",
    //     "location": "Mumbai, India",
    //     "designation": "Front-end Intern",
    //     "role": "Front-end Developer",
    //     "highlights": [
    //         "Redeveloped a software in Angular 4 and Redux (for state management) which had a previous version in AngularJS",
    //         "Used SlickGrid to develop high performant and scalable grids to handle huge amounts of data",
    //         "Bagged a Pre-Placement Offer for the performance during the internship"
    //     ]
    // },
    {
        "year": {
            "from": "Aug, 2018",
            "to": "Dec, 2018"
        },
        "type": "FTE",
        "company": "Morgan Stanley",
        "location": "Bengaluru, India",
        "designation": "Analyst",
        "role": "Full Stack Software Engineer",
        "highlights": [
            "Underwent four months of Application Development Training in various technologies like NodeJS, Python, Shell etc",
            "Developed a chat-bot application using Stanford CoreNLP API and Java Spring REST Server for Skype for Business",
            "Refined models for Named Entity Recognition and Intent Classification to increase overall accuracy",
            "Trained the chat-bot NLP model to retrieve data from DB2 Database with an accuracy of 83%"
        ]
    },
    {
        "year": {
            "from": "Jan, 2019",
            "to": "Dec, 2019"
        },
        "type": "FTE",
        "company": "Morgan Stanley",
        "location": "Bengaluru, India",
        "designation": "Associate",
        "role": "Full Stack Software Engineer",
        "highlights": [
            "Developed data visualization dashboard in React using ag-Grid with customized CSS design",
            "Took initiative to increase code-coverage and promote TDD for the project",
            "Improved Development, Testing and Deployment time by optimizing CI/CD pipeline in Teamcity/Jenkins",
            "Led Development of a chat application using Stanford CoreNLP API to make Accounts processes faster",
            "Refined models for Named Entity Recognition and Intent Classification to achieve an accuracy of 83%",
        ]
    },
    {
        "year": {
            "from": "Jan, 2020",
            "to": "Jan, 2021"
        },
        "type": "FTE",
        "company": "Morgan Stanley",
        "location": "Bengaluru, India",
        "designation": "Senior Associate",
        "role": "Full Stack Software Engineer",
        "highlights": [
            "Spearheaded development of flagship Wealth Management reports using Angular and Highcharts",
            "Illustrated use of Micro-frontend with Web Components using Angular and React",
            "Conceptualized Docker integration for Angular inside Morgan Stanley Development Environment",
            "Used NgRx in the application for reactive state management and component communication",
            "Optimized the RESTful API on JAVA Springboot and Jetty to achieve faster upto 50% APIs",
        ]
    },
    {
        "year": {
            "from": "Feb, 2021",
            "to": "Apr, 2023"
        },
        "type": "FTE",
        "company": "NetApp India",
        "location": "Bengaluru, India",
        "designation": "Member of Technical Staff III",
        "role": "Full Stack Software Engineer",
        "highlights": [
            "Led, developed and maintained Flagship tool ActiveIQ written in Angular with Redux state management",
            "Architected multi-stack AIQ Health Assessment to reduce client service time to a few minutes",
            "Improved static content delivery time by 80% by designing a performant and secure Storage API with AWS CloudFront and Lambda@Edge",
            "Achieved cost efficiency of upto 90% over served APIs by using AWS Lambda functions to deliver low latency RESTful APIs in Python and NodeJS",
            "Consolidated data reliabilty and user experience by optimizing multi-threaded Python ETLs",
            "Devised GraphQL schemas and queries in TypeGraphQL to achieve 50% lower latency",
            "Led and created a Support Utility under a week reducing ticket response lead time to a few minutes",
        ]
    },
    {
        "year": {
            "from": "Apr, 2023",
            "to": "Present"
        },
        "type": "FTE",
        "company": "Gushwork.ai",
        "link": "https://gushwork.ai",
        "location": "Bengaluru, India",
        "designation": "Founding Engineer",
        "role": "Full Stack Software Engineer",
        "highlights": [
            "Building something exciting 🚀"
        ]
    }
]