import "./logo.css";
import React from "react";

function template() {
  return (
    <div className="logo">P</div>
  );
};

export default template;
