import React    from "react";
import template from "./content.jsx";
import Data from './../../utils/data'

class content extends React.Component {
  state = {
    "sections": Data
  }
  render() {
    return template.call(this);
  }
}

export default content;
