import React    from "react";
import template from "./logo.jsx";

class logo extends React.Component {
  render() {
    return template.call(this);
  }
}

export default logo;
