import "./skills.css";
import React from "react";
import Title from './../common/title';
import { ReactComponent as MySQLIcon } from './../../utils/mysql.svg';
import { ReactComponent as MongoDBIcon } from './../../utils/mongodb.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function template() {
  return (
      <div id="skills_anchor" className="skills">
        <Title>Skills</Title>
        <div id="scroll-section" className="skill-list overflow-auto mb-5">
        
          { this.state.list.map((item, i) => <OverlayTrigger key={i} placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>{item.text}</Tooltip>}><i alt={item.text} className={"lni lni-"+ item.icon}></i></OverlayTrigger>)}

          <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom mt-n2`}>MySQL</Tooltip>}>
            <MySQLIcon alt="MySQL" className="mysql"></MySQLIcon>
          </OverlayTrigger>
          <div className="mongodb-container">
          <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>MongoDB</Tooltip>}>
            <MongoDBIcon alt="MongoDB" className="mongodb"></MongoDBIcon>
          </OverlayTrigger>
          </div>
        </div>
      </div>
  );
};

export default template;
