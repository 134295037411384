import "./title.css";
import React from "react";

function template() {
  return (
    <div className="title">
      <h1>{this.props.children}</h1>
    </div>
  );
};

export default template;
