import React    from "react";
import template from "./aboutme.jsx";

class aboutme extends React.Component {
  render() {
    return template.call(this);
  }
}

export default aboutme;
