import React    from "react";
import template from "./vspace.jsx";

class vspace extends React.Component {
  render() {
    return template.call(this);
  }
}

export default vspace;
