import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Title from './../common/title';
import VSpace from './../common/vspace';
import "./contactme.css";

function template() {
  return (
    <div id="contactme_anchor" className="contactme">
      <Title>Contact Me</Title>
      <div className="contactme-container d-flex justify-content-center">
        <form action="https://formspree.io/f/xgeqlbqq" method="POST" onSubmit={e => this.handleSubmit(e, this)} onChange={e => this.formChange(e, this)}>
        {/* <form action="#" method="POST" onSubmit={e => this.handleSubmit(e, this)} onChange={e => this.formChange(e, this)}> */}
          <div className="row">
            <div className="form-cover col-12 col-md-6 mt-4">
              {/* <label htmlFor="name" className="form-label">Name</label> */}
              <input className="form-item" type="text" id="name" name="name" placeholder="Name"  />
            </div>
            <div className="form-cover col-12 col-md-6 mt-4">
              {/* <label htmlFor="email" className="form-label">Email</label> */}
              <input className="form-item" type="email" id="email" name="email" placeholder="Email"  />
            </div>
          </div>
          <div className="row mt-4">
            <div className="form-cover col-12">
              {/* <label htmlFor="message">Message</label> */}
              <textarea className="form-item" id="message" name="message" placeholder="Message" ></textarea>
            </div>
          </div>
          <ReCAPTCHA ref={this.recaptchaRef} className="mt-4" onChange={e => this.onRecaptchaChange(e, this)} sitekey="6Lc1exUTAAAAALLT8eUl1xJY_uvd3FLf4xKmtD6B" />
          <button className="cbtn cbtn-primary d-inline-block contactme-btn mt-4" type="submit">Send</button>
        </form>
        <div className="error-msg mt-3">{this.state.error_msg}</div>
        <VSpace multiple={5}></VSpace>
      </div>
    </div>
  );
};

export default template;
