export const Skills = [
    { "icon": "angular", "text": "Angular", "hovercolor": "#dd0031"},
    { "icon": "nodejs-alt", "text": "NodeJs", "hovercolor": "#dd0031"},
    { "icon": "react", "text": "React", "hovercolor": "#dd0031"},
    { "icon": "python", "text": "Python", "hovercolor": "#dd0031"},
    { "icon": "aws", "text": "AWS", "hovercolor": "#dd0031"},
    { "icon": "git", "text": "Git", "hovercolor": "#dd0031"},
    { "icon": "javascript", "text": "Javascript", "hovercolor": "#dd0031"},
    { "icon": "docker", "text": "Docker", "hovercolor": "#dd0031"},
    { "icon": "css3", "text": "CSS3", "hovercolor": "#dd0031"},
    { "icon": "html5", "text": "HTML", "hovercolor": "#dd0031"},
    { "icon": "github-original", "text": "Github", "hovercolor": "#dd0031"},
]