import "./navbar.css";
import React from "react";
import Logo from "./../logo"

function template() {
  return (
    <nav className="navbar navbar-expand-lg page-header">
      <div className="container-fluid">
        <a className="navbar-brand name" href="#"><Logo></Logo><h1><span className="fw-bold">Punit</span> Pal</h1></a>
        <button className="navbar-toggler toggler-button" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
          <i className="lni lni-menu"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" onClick={this.goto('aboutme')} href="#">About Me</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={this.goto('skills')} href="#">Skills</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={this.goto('education')} href="#">Education</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={this.goto('work_experience')} href="#">Work Experience</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={this.goto('contactme')} href="#">Contact Me</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default template;
