import "./experience-item.scss";
import React from "react";

function template() {
  const {data} = this.props
  return (
    <div className="experience-item">
      <div className="company-row d-flex">
        {
          data.link ? <a href={data.link} target="_blank" rel="noopener noreferrer" className="company">{data.company}</a> : <span className="company">{data.company}</span>
        }
      </div>
      <div className="designation mt-2">{data.designation}</div>
      <div className="highlights mt-2">
        <ul>
          {data.highlights.map((el,i) => <li key={i} className="align-items-end">{el}</li>)}
        </ul>
      </div>
    </div>
  );
};

export default template;
